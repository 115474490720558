import {useReadBalanceCheckerBalances} from "@/util/generated";
import {BALANCE_CHECKER_ADDRESS, WASABI_ETH_ADDRESS, WETH_ADDRESS} from "@/util/chainConstants";
import {zeroAddress} from "viem";
import {useAccount} from "wagmi";
import React from "react";
import {formatEther, prettifyNumber} from "@/util/converters";
import {EthValue} from "@/components/Perps/EthValue";
import {Tooltip as ReactTooltip} from "react-tooltip";

export const UserBalanceView = () => {
  const {address} = useAccount();
  // const {ethPrice} = useEthPrice();
  const tokenAddresses = [zeroAddress, WETH_ADDRESS, WASABI_ETH_ADDRESS];

  const userBalance = useReadBalanceCheckerBalances({
    address: BALANCE_CHECKER_ADDRESS,
    args: [[address || zeroAddress], tokenAddresses],
    query: { enabled: BALANCE_CHECKER_ADDRESS !== zeroAddress && !!address }
  });

  if (userBalance.isLoading || userBalance.error) {
    return (
      <div className="min-w-[100px] h-full bg-slate-600 animate-pulse"/>
    )
  }

  const getTotalBalance = (): bigint => {
    if (address && userBalance.data) {
      return userBalance.data.reduce((a, b) => BigInt(a) + BigInt(b), 0n);
    }
    return 0n;
  }

  const renderBalance = (symbol: string, balance: bigint) => {
    const amount = formatEther(balance);

    return (<div className="flex flex-row gap-2 items-center justify-between">
      <div className="flex flex-row gap-2 items-center">
        <img src="https://wasabi-public.s3.amazonaws.com/tokens/weth.png"
             alt={symbol}
             className="w-4 h-4 rounded-full"/>
        <span className="text-neutral-content">{symbol}</span>
      </div>
      <span>{amount.toLocaleString([], {maximumFractionDigits: 2})}</span>
    </div>)
  }

  return (
    <>
      <EthValue id="user_balance"
                value={getTotalBalance()}
                className="flex items-center h-[40px] md:h-[60px] px-2 md:px-4 hover:bg-neutral-content/20 hover:cursor-pointer" />
      <ReactTooltip
        anchorSelect="#user_balance"
        id="user_balance_tooltip"
        place="bottom-start"
        offset={0}
        className="z-50"
        style={{
          opacity: 1,
          backgroundColor: "unset"
        }}
      >
        <div className="text-base standard-stack min-w-[240px] !gap-4 border rounded-md p-4 overflow-clip bg-[#090f17]">
          {renderBalance("ETH", userBalance.data ? userBalance.data[0] : 0n)}
          {renderBalance("WETH", userBalance.data ? userBalance.data[1] : 0n)}
          {renderBalance("wWETH", userBalance.data ? userBalance.data[2] : 0n)}
          {/*<hr className="border-neutral-content/50 w-full"/>*/}
          {/*{renderBalance("Total", getTotalBalance())}*/}
          {/*<hr className="border-neutral-content/50 w-full"/>*/}
          {/*{ethPrice && <span className="text-right w-full text-sm text-neutral-content">*/}
          {/*  ${(formatEther(getTotalBalance()) * ethPrice).toLocaleString([], {maximumFractionDigits: 2})}*/}
          {/*</span>}*/}
        </div>
      </ReactTooltip>
    </>
  );
}
