import {FaEthereum} from "react-icons/fa";
import {formatEther} from "ethers/lib/utils";
import {GoTriangleDown, GoTriangleUp} from "react-icons/go";
import {twMerge} from "tailwind-merge";
import useId from "@/hooks/useId";
import {Tooltip as ReactTooltip} from "react-tooltip";
import React from "react";
import {formatValue} from "@/util/converters";

export interface Props {
  value: bigint;
  valueForPercent?: bigint;
  original?: bigint | undefined;
  fractions?: number | undefined;
  className?: string | undefined;
  valueClassName?: string | undefined;
  percentClassName?: string | undefined;
  showPlus?: boolean;
  id?: string | undefined;
  ethPrice?: number;
  iconSize?: number;
  showInUsd?: boolean;
}

const computeFractions = (value: bigint) => {
  const val = Math.abs(Number(formatEther((value))));
  if (val > 10) {
    return 1;
  } else if (val > 1) {
    return 3;
  } else if (val > 0.1) {
    return 3;
  } else if (val > 0.01) {
    return 4;
  } else if (val > 0.0001) {
    return 5;
  }
  return 6;
}

const computeFractionsUsd = (usdValue: number) => {
  const val = Math.abs(usdValue);
  if (val > 100) {
    return 0;
  } else if (val > 10) {
    return 1;
  } else if (val > 1) {
    return 2;
  } else if (val > 0.1) {
    return 3;
  }
  return 4;
}

export const EthValue = ({ id, value, valueForPercent, original, fractions, className, showPlus, valueClassName, ethPrice, percentClassName, iconSize, showInUsd }: Props) => {
  const divId = useId(id);

  const fractionsToUse = fractions || computeFractions(value);
  const valueToUse = valueForPercent !== undefined ? valueForPercent : value;
  let percentValue: number | undefined;
  if (original) {
    const denom = 1000;
    percentValue = (Number(valueToUse * BigInt(denom) / original) / denom - 1) * 100;
  }

  if (showInUsd && ethPrice) {
    const usdValue = Number(formatEther(value)) * ethPrice;
    return (
      <div id={divId} className={className}>
        ${usdValue.toLocaleString([], {maximumFractionDigits: computeFractionsUsd(usdValue)})}
      </div>
    )
  }

  return (
    <>
      <div id={divId} className={twMerge("flex flex-row gap-1 items-center", className || '')}>
      <span className={twMerge("flex flex-row items-center gap-1", valueClassName || '')}>
        <FaEthereum size={iconSize || 12} />
        {showPlus && value > 0 ? "+" : ""}{Number(formatEther(value)).toLocaleString([], {maximumFractionDigits: fractionsToUse })}
      </span>
        {
          percentValue !== undefined && <>
            {
              percentValue > 0 &&
              <span className={twMerge("flex items-center text-xs text-call", percentClassName || '')}>
              (<GoTriangleUp /> {parseFloat(percentValue.toFixed(fractionsToUse - 1))}%)
            </span>
            }
            {
              percentValue < 0 &&
              <span className={twMerge("flex items-center text-xs text-put", percentClassName || '')}>
              (<GoTriangleDown /> {parseFloat((-percentValue).toFixed(fractionsToUse - 1))}%)
            </span>
            }
            {
              percentValue == 0 &&
              <span className={twMerge("flex items-center text-xs text-neutral-content", percentClassName || '')}>
              (<GoTriangleDown /> 0%)
            </span>
            }
          </>
        }
      </div>
      {
        ethPrice &&
        <ReactTooltip
          anchorSelect={`#${divId}`}
          id={`eth_value_tooltip_${divId}`}
          place="top"
          noArrow
          className="z-50 text-lg font-bold"
          content={ethPrice ? `$ ${formatValue(BigInt(value) * BigInt(ethPrice.toFixed(0)), 2).toLocaleString()}` : ''}
          style={{ backgroundColor: "#3b485f", color: "#98a2b3" }}
        />
      }
    </>
  )
}