import {useQuery} from "@tanstack/react-query";
import {fetchUserStats} from "@/api/perpsDataFetcher";
import {useCurrentUser} from "@/hooks/useCurrentUser";
import {Tooltip as ReactTooltip} from "react-tooltip";
import classNames from "classnames";
import {useRouter} from "next/router";

export const WasabiPointsView = () => {
  const {address} = useCurrentUser();
  const router = useRouter();

  const {isLoading, data: stats, isError} = useQuery({
    queryKey: ["user-stats", address],
    queryFn: async () => await fetchUserStats(address!),
    enabled: !!address && router.isReady,
    refetchInterval: 60 * 1000
  });

  const renderSkeletonLoading = () => (
    <div className="min-w-[300px] min-h-[100px] bg-slate-600 animate-pulse"/>
  );

  const WasabiPointsTooltip = () => {
    return (
      <>
        {
          stats && !isLoading ?
            <div
              className="standard-stack min-w-[250px] !gap-4 border rounded-md p-4 overflow-clip border-camo-500 transition-colors bg-gradient-to-b from-[#090f17] from-[85.00%] to-green-700">
              <div className="flex flex-row gap-2 w-full justify-between">
                <div className="flex flex-col items-start">
                  <span className="text-neutral-content text-xs">TRADING POINTS</span>
                  <p
                    className="text-white text-2xl">{stats.tradePoints.toLocaleString(undefined, {maximumFractionDigits: 0})}</p>
                </div>
                <div className="flex flex-col items-end">
                  <span className="text-neutral-content text-xs">LP POINTS</span>
                  <p
                    className="text-white text-2xl">{((stats.leaderboardItem?.lPPoints || 0) * 100).toLocaleString(undefined, {maximumFractionDigits: 0})}</p>
                </div>
              </div>
            </div> :
            renderSkeletonLoading()
        }
      </>
    );
  }

  return (
    stats ?
      <>
        <div id="wasabi_points"
             className={classNames("flex items-center h-[40px] md:h-[60px] px-2 md:px-4 hover:bg-neutral-content/20 hover:cursor-pointer")}>
          <div className="flex flex-row items-end justify-between cursor-pointer gap-2">
                <span
                  className="italic text-base text-[#94fd08]">{stats.tradePoints.toLocaleString(undefined, {maximumFractionDigits: 0})}</span>
            <img src="/static/logo_nobackground.png" className="h-6 w-6" alt="wasabi_earning"/>
          </div>
        </div>
        <ReactTooltip
          anchorSelect="#wasabi_points"
          id="tooltip_wasabi_points"
          place={"bottom"}
          offset={0}
          className="z-50"
          style={{
            opacity: 1,
            backgroundColor: "unset"
          }}
        >
          <WasabiPointsTooltip/>
        </ReactTooltip>
      </>
      : null
  );
}
