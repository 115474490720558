import { ImSearch, ImCross } from "react-icons/im";
import React, { useState, useRef, useEffect, ChangeEvent, ClipboardEvent } from "react";
import { searchCollections } from "@/api/datafetcher";
import { useRouter } from "next/router";
import { LoadingSpinner } from "@/components/LoadingSpinner";
import { Collection, PaginatedResponse } from "@/types/types";
import { debounce } from "lodash";
import classNames from "classnames";
import {useScreen} from "@/util/useScreen";
import ReactImageFallback from "react-image-fallback";

type SearchResultProps = {
  results: Collection[];
  title: string;
  renderCross: boolean;
};

const minSearchCharCount = 2;

const debouncedFetchData = debounce((query: string, cb: (res: PaginatedResponse<Collection>) => any) => {
  if (query.length > 0) {
    searchCollections(query).then(cb);
  }
}, 500);

export const SearchBar = () => {
  const isSmall = useScreen("xl");
  const [searchText, setSearchText] = useState<string>("");
  const [searchResults, setSearchResults] = useState<Collection[]>([]);
  const [storedResults, setStoredResults] = useState<Collection[]>([]);
  const componentRef = useRef<HTMLDivElement | null>(null);
  const inputRef = useRef<HTMLInputElement | null>(null);
  const router = useRouter();
  const [isFocused, setIsFocused] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleSearch = async (text: string) => {
    setIsLoading(true);
    setSearchResults([]);
    debouncedFetchData(text, (res: PaginatedResponse<Collection>) => {
      setIsLoading(false);
      setSearchResults(res.items);
    });
  };

  const handleInputPaste = (event: ClipboardEvent<HTMLInputElement>) => {
    const pastedText = event.clipboardData.getData("text");
    event.preventDefault();
    if (pastedText.length >= minSearchCharCount) {
      setSearchText(pastedText);
      handleSearch(pastedText);
    }
  };

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const inputValue = event.target.value;
    setSearchText(inputValue);

    if (inputValue.length >= minSearchCharCount) {
      handleSearch(inputValue);
    } else {
      setSearchResults([]);
    }
  };

  useEffect(() => {
    // Attach click event listener when the component mounts
    document.addEventListener('click', handleOutsideClick);

    // Remove the click event listener when the component unmounts
    return () => {
      document.removeEventListener('click', handleOutsideClick);
    };
  }, []);

  const handleOutsideClick = (event: MouseEvent) => {
    // Check if the click target is outside the component
    // @ts-ignore
    if (componentRef.current && !componentRef.current!.contains(event.target)) {
      setIsFocused(false);
    }
  };

  const handleButtonClicked = () => {
    const storedResultsString = localStorage.getItem("selectedResults");

    if (storedResultsString) {
      const parsedResults: Collection[] = JSON.parse(storedResultsString);
      setStoredResults(parsedResults.reverse());
    }

    if (inputRef.current && !isFocused) {
      inputRef.current.focus();
    }

    setIsFocused(true);
  };

  const onCollectionClick = (collection: Collection) => {
    setSearchText("");
    setIsFocused(false);

    const storedResultsString = localStorage.getItem("selectedResults");
    let resultsArray: Collection[] = [];

    if (storedResultsString) {
      resultsArray = JSON.parse(storedResultsString);
    }

    const hasDuplicate = resultsArray.some(
      (item) => item.contractAddress === collection.contractAddress
    );

    if (!hasDuplicate) {
      if (resultsArray.length >= 10) {
          resultsArray.shift();
      }

      resultsArray.push(collection);
      localStorage.setItem("selectedResults", JSON.stringify(resultsArray));
    }
    let stringToPush: string;
    if (collection.range) {
      stringToPush = `/collection/${collection.contractAddress}:${collection.range}`;
    } else {
      stringToPush = `/collection/${collection.contractAddress}`;
    }

    router.push(encodeURI(stringToPush));
    setSearchResults([]);
  };

  const removeRecentlyVisited = (result: Collection) => {
    const updatedResults = storedResults.filter(
      (item) => item.contractAddress.toLowerCase() !== result.contractAddress.toLowerCase()
    );
    localStorage.setItem("selectedResults", JSON.stringify(updatedResults));
    setStoredResults(updatedResults);
  };

  useEffect(() => {
    const storedResultsString = localStorage.getItem("selectedResults");
    if (storedResultsString) {
      const parsedResults: Collection[] = JSON.parse(storedResultsString);
      setStoredResults(parsedResults);
    }
  }, []);

  const renderItem = (collection: Collection, index: number, renderCross: boolean) => {
    return (
      <li
        key={index}
        className="w-full flex flex-row items-center justify-between hover:bg-gray-700 hover:text-white hover:brightness-125 hover:cursor-pointer p-2"
        onClick={e => {
          e.stopPropagation();
          onCollectionClick(collection);
        }}
      >
        <div className="flex flex-row gap-2 items-center">
          <ReactImageFallback
            src={collection.imageThumbnailUrl}
            fallbackImage="/static/fallback.png"
            initialImage="/static/dkoda_loading.svg"
            className="w-8 h-8 rounded-full"
          />
          <span className="text-white">{collection.name}</span>
        </div>
        {
          renderCross &&
          <ImCross
            className="cursor-pointer hover:scale-125 hover:text-white text-neutral-content"
            size={12}
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();
              removeRecentlyVisited(collection);
            }}
          />
        }
      </li>
    );
  }

  const CollectionList = ({ results, title, renderCross }: SearchResultProps) => {
    if (results.length === 0 && renderCross) {
      return null;
    }

    return (
      <div className="bg-gray-800 w-full">
        <div className="px-3 py-2 text-neutral-content bg-glass font-normal">{title}</div>
        {
          !renderCross && results.length === 0 &&
          <div className="p-3">No Results</div>
        }
        <ul>{results.map((c, i) => renderItem(c, i, renderCross))}</ul>
      </div>
    );
  };

  return (
    <div onClick={handleButtonClicked}
         ref={componentRef}
         className={classNames("w-full flex flex-row items-center max-w-[400px]", {
           "hover:bg-neutral-content hover:text-base-100 hover:brightness-125 hover:cursor-pointer": !isFocused
         })}>
      <ImSearch className="searchbar-icon ml-4 mr-4 cursor-pointer" size={12} />
      <input
        ref={inputRef}
        className={classNames("h-10 inline-block transition-all duration-300 ease-in-out cursor-pointer border-none outline-none bg-transparent", {
            "xl:w-[400px] lg:w-[70px] md:w-[50px] max-w-sm w-[125px] hover:cursor-text": isFocused,
            "w-0 hover:cursor-pointer": !isFocused
        })}
        type="text"
        placeholder={isSmall ? "Search by name or contract address" : "Search"}
        value={searchText}
        onChange={handleInputChange}
        onPaste={handleInputPaste}
        autoComplete="off"
      />
      {
        isFocused && (searchText.length >= minSearchCharCount || storedResults.length > 0) && (
        <div className="fixed lg:top-[65px] md:top-[125px] max-w-sm min-w-[400px] top-[125px] mt-2 z-50 rounded-md border border-gray-400 overflow-hidden shadow-md">
          {
            isLoading ?
              <div className="p-4 bg-gray-800 ">
                <LoadingSpinner />
              </div> :
              <CollectionList
                renderCross={searchText.length < minSearchCharCount}
                results={searchText.length >= minSearchCharCount ? searchResults : storedResults}
                title={searchText.length >= minSearchCharCount ? "RESULTS" : "RECENT"}/>
          }
        </div>
      )}
    </div>
  );
};
