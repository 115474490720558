import React, {useContext, useEffect} from "react";
import {UserBalanceContext} from "@/contexts/UserBalanceContext";
import {ConnectButton} from "@rainbow-me/rainbowkit";
import classNames from "classnames";
import {prettifyEth, prettifyNumber, toBN} from "@/util/converters";
import {useEnsAvatar, useEnsName} from "wagmi";
import {FaEthereum} from "react-icons/fa";
import {FiShoppingCart, FiSearch} from "react-icons/fi";
import {useProSidebar} from "react-pro-sidebar";
import {ShoppingCartContext} from "@/contexts/ShoppingCartContext";
import {useAccountPassBalance} from "@/hooks/useAccountPassBalance";
import pass from "@/pages/pass";
import {useQuery} from "@tanstack/react-query";
import {fetchUserEntry} from "@/api/datafetcher";
import {Address} from "viem";
import { SearchBar } from "../SearchBar";
import {Modal, useModal} from "@/components/Modal";
import {MdNotifications } from "react-icons/md";
import { NotificationInfo } from "../NotificationInfo";
import {useScreen} from "@/util/useScreen";
import {PERPS_VIEW, CHAIN_ID, isBlast} from "@/util/constants";
import {twMerge} from "tailwind-merge";
import {BlastPointsView} from "@/components/Perps/BlastPointsView";
import {EthValue} from "@/components/Perps/EthValue";
import {WasabiPointsView} from "@/components/Perps/WasabiPointsView";
import {UserBalanceView} from "@/components/Header/UserBalanceView";

const iClassName = "h-10 px-3 flex items-center hover:bg-neutral-content hover:text-base-100 hover:brightness-125 hover:cursor-pointer";

export const UserHeaderButton = ({ address, className, itemAClassName }: { address: string, className?: string | undefined, itemAClassName?: string | undefined }) => {
  const isPerpsView = PERPS_VIEW;

  const itemClassName = itemAClassName || iClassName;

  const isDesktop = useScreen('md');
  const passBalance = useAccountPassBalance();
  const { data: name } = useEnsName({
    address:  address as Address,
    query: { enabled: !isBlast }
  });
  const { data: avatar} = useEnsAvatar({
    name: name as string,
    query: { enabled: !isBlast }
  });

  const { collapseSidebar, collapsed } = useProSidebar();
  const { total } = useContext(ShoppingCartContext);

  const userStatsQuery = useQuery({
    queryKey: ['userStats', address],
    queryFn: async () => await fetchUserEntry(address, false),
    enabled: !isPerpsView
  })

  const notificationModal = useModal();

  useEffect(() => {
    if (!isPerpsView && localStorage?.getItem('has_shown_notification_info') !== 'true') {
      notificationModal.show();
      localStorage.setItem('has_shown_notification_info', 'true');
    }
  }, []);


  return (
    <div className={twMerge("flex flex-row items-center justify-center divide-x-[1px] divide-neutral-content tracking-wider font-semibold rounded-md text-xs disabled:opacity-50 disabled:cursor-not-allowed text-neutral-content border border-neutral-content overflow-hidden box-border", className || '')}>
      {
        !isPerpsView &&
        <a className="flex items-center">
          <SearchBar/>
        </a>
      }
      {
        !isPerpsView &&
        <a className={itemClassName} onClick={notificationModal.show}>
          <MdNotifications size={18} />
        </a>
      }
      {
        !isPerpsView && isDesktop &&
        <a className={itemClassName} href="/leaderboard">
          {prettifyNumber(userStatsQuery.data?.entry.points || 0)} PTS
        </a>
      }
      {
        isDesktop && isPerpsView && isBlast &&
        <a className="flex items-center" href="/points">
          <BlastPointsView />
        </a>
      }
      {
        isDesktop && isPerpsView &&
          <a className="flex items-center" href="/points">
              <WasabiPointsView />
          </a>
      }
      {
        isDesktop && !passBalance.isLoading && <>
          {
            passBalance.balance === 0 ?
            <a className={itemClassName} href="/pass">
              BOOST
            </a> :
            <a className={classNames(itemClassName, {
              "text-call": passBalance.balance > 0,
              "text-neutral-content": passBalance.balance === 0
            })} href="/pass">
              {passBalance.multiplier}x BOOST
            </a>
          }
        </>
      }
      {
        isPerpsView && (CHAIN_ID !== 1 && !isBlast) && <a className={itemClassName} href="/start">
          GET TOKENS
        </a>
      }
      { isDesktop && <UserBalanceView /> }
      <ConnectButton.Custom>
        {({ openAccountModal }) => {
          return (
            <div className={itemClassName + " flex flex-row gap-1"}
                 onClick={openAccountModal}>
              {
                avatar
                  ? <img src={avatar} alt="ens_avatar" className="w-6 h-6 rounded-full"/>
                  : <div className={classNames("rounded-full overflow-hide w-2 h-2 bg-call pulse-green-animate")}></div>
              }
              {
                name?.toLowerCase() || address.slice(0, 6)
              }
            </div>
          );
        }}
      </ConnectButton.Custom>
      {
        !isPerpsView &&
        <div className="h-10 px-3 flex flex-row items-center hover:bg-neutral-content hover:text-base-100 hover:brightness-125 hover:cursor-pointer px-4 h-full relative inline-flex items-center cursor-pointer min-h-[40px]"
             onClick={() => collapseSidebar(!collapsed)}>
          {
            total > 0 &&
            <span className="absolute top-[4px] right-[4px] h-[14px] w-[14px] rounded-full flex justify-center items-center items text-2xs bg-error text-white">
            {total}
          </span>
          }
          <FiShoppingCart size={18} />
        </div>
      }
      {
        <Modal {...notificationModal}>
          <NotificationInfo
                     onShowed={() => {
                       notificationModal.hide()
                     }}
          />
        </Modal>
      }
    </div>
  )
}
