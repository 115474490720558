import {fetchPerpInfoMessage} from "@/api/perpsDataFetcher";
import {useQuery} from "@tanstack/react-query";
import {useRouter} from "next/router";
import {PERPS_VIEW} from "@/util/constants";
import {InfoMessage} from "@/components/Perps/types";
export const InfoMessageBanner = () => {
    const isPerpsView = PERPS_VIEW;
    const router = useRouter();

    const {data} = useQuery<InfoMessage[]>({
        queryKey: ["info-message"],
        queryFn: async () => await fetchPerpInfoMessage(),
        enabled: isPerpsView && router.isReady,
        refetchInterval: 30 * 1000,
      }
    );

    return (
        data && (
            <div>
                {data.map((item, index) => (
                    <div key={index} className={`flex flex-row justify-center items-center ${getBannerColorClass(item.level)}`}>
                        <div className="flex flex-row justify-center items-center gap-2">
                            <span className="text-xs font-bold">
                                {item.message}
                            </span>
                        </div>
                    </div>
                ))}
            </div>
        ) || null
    );
}

function getBannerColorClass(level: string) {
    switch (level) {
        case 'INFO':
            return 'bg-neutral-content/20 text-neutral-content';
        case 'WARNING':
            return 'bg-orange-400 text-black';
        case 'ERROR':
            return 'bg-red-600 text-black';
        default:
            return '';
    }
}
