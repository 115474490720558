import React from "react";
import { Button, ButtonType } from "@/components/Button";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { FaTelegramPlane } from "react-icons/fa";
import {BiLinkExternal} from "react-icons/bi";
import Link from "next/link";

interface Props {
  onShowed?: Function;
}

export const NotificationInfo = ({ onShowed }: Props) => {
  
  return (
    <div className="standard-frame p-6 standard-stack !gap-4 items-center max-w-[900px]">
      <span className="w-full flex flex-row gap-2 items-center capitalize">
        <FaTelegramPlane size={30} /> Setup Notifications
      </span>
      <hr className="border-neutral-content/50 w-full" />
      <p className='text-sm'>
        Subscribe to our Telegram Bot using your wallet address<br/> to receive notifications about your positions.
      </p>
      <Link className="wasabi-button wasabi-button-secondary"
            href="https://t.me/wasabi_options_bot"
            target="_blank">
        Open Telegram Bot <BiLinkExternal />
      </Link>
    </div>
  );
};
